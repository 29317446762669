<template>
    <section class="section-main custom-scroll overflow-auto">
        <cargando v-if="cargando" />
        <div v-for="(seccion,key) in secciones" :key="key" class="px-2 px-md-5 wrapp-components">
            <titulos-component v-if="[21,22,23].includes(seccion.tipo)" :data="seccion" class="mb-3" />

            <imagenes-component v-if="[11,12].includes(seccion.tipo)" :data="seccion" class="mb-3" />


            <categorias-component v-if="[31,32].includes(seccion.tipo)" class="mb-3" :data="seccion" />


            <productos-component v-if="[41,42].includes(seccion.tipo)" :data="seccion" class="mb-3" />
        </div>
        <section-footer />
        <redes-sociales />

        <div v-if="secciones.length === 0" class="row">
            <div class="col-12 d-middle-center">
                <p class="f-600 f-60">
                    Bienvenido
                </p>
            </div>
        </div>
        <mobile-modal :type="agentUser" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Service from '~/services/invitado/invitado'
export default {
    components: {
        titulosComponent: () => import("./components/titulosComponent"),
        categoriasComponent: () => import("./components/categoriasComponent"),
        imagenesComponent: () => import("./components/imagenesComponent"),
        productosComponent: () => import("./components/productosComponent"),
        redesSociales: () => import('./components/redesSociales.vue'),
        mobileModal: () => import('./components/mobileModal.vue'),
        // sectionFooter: () => import('./components/sectionFooter.vue'),
    },
    data(){
        return{
            cargando:false,
            agentUser: '',
            
        }
    },
    computed:{
        ...mapGetters({
            secciones:'home/secciones',
            homeCache:'home/homeCache',
            isInvitado:'home/isInvitado',
            tiendaInvitado: 'invitado/tienda',
            carritoCache: 'invitado/carrito',
        })
    },
    async mounted(){
        await this.sincronizarCarrito()
        this.getHome()
        this.guardarVisita()
        this.getMobileOperatingSystem()
    },
    methods: {
       
        getMobileOperatingSystem(){
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)){
                this.agentUser = 'Android'
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
                this.agentUser = 'iOS'
            }
        },
        async getHome(){
            try {

                let params = {}

                if(this.$usuario.id_tienda){
                    params.idTienda = this.$usuario.id_tienda
                }else{
                    if(!this.tiendaInvitado.id_tienda){
                        await this.setTiendaDefault(0)
                    }
                    params.idTienda = this.tiendaInvitado.id_tienda
                }


                console.log('estado home', this.homeCache);
                if(this.homeCache === false){
                    this.cargando = true

                    const response = await this.$store.dispatch('home/getHome',params)
                    if(response.tienda != null){
                        const tienda = JSON.stringify(response.tienda);
                        localStorage.setItem('tienda', tienda);
                        this.$store.commit('invitado/obtenerTienda', response.tienda);
                    }
                }
            } catch (error){
                this.errorCatch(error)
            } finally {
                this.cargando = false
            }
            
        },
        async sincronizarCarrito(){
            if(this.$usuario.id_tienda){

                if(this.carritoCache && this.carritoCache.length){
                    //console.log('el carrito esta pesado');

                    const form = {
                        carrito: JSON.stringify(this.carritoCache)
                    }
                    await this.$store.dispatch('home/syncCarrito',form)
                    localStorage.removeItem('carritoInvitado');
                    this.$store.commit('invitado/obtenerCarrito', []);
                    this.$store.dispatch(`auth/consultarUsuario`)

                }


                //console.log('usuario logueado');
            }
        },
        guardarVisita(){
            this.$store.dispatch('home/guardarVisita')
        },
        async setTiendaDefault(idCiudad = null){
            const { data } = await Service.getTienda(idCiudad);
            const tienda = JSON.stringify(data.tienda);
            localStorage.setItem('tienda', tienda);
            this.$store.commit('invitado/obtenerTienda', data.tienda);
        },
    }
}
</script>
<style lang="scss" scoped>
.float-tab{
    width: 46px;
    right: 33px;
    color: #FFFFFF;
    height: 148px;
    right: 0px;
    position: absolute;
    background-color: #612AD5;
    border-radius: 12px 0px 0px 12px;
    top: 391px;

}
.section-main{
	height:calc(100vh - 80px)
}
@media screen and (max-width: 576px){
	.section-main{
		height:calc(100vh - 80px - 90px) !important;
	}
}
</style>